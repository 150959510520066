import React from "react"
import { ContentContainer, ContentSection } from "./style"
import { PageWrapper } from "components/PageWrapper"
import { PageTitle } from "components/reusableComponents"
import { ActionPlanTextWrapper } from "components/ActionPlanScreen/style"
const CenteredContentScreen = ({ data }) => {
  return (
    <PageWrapper>
      {data.heading && (
        <ActionPlanTextWrapper>
          <PageTitle>{data.heading}</PageTitle>
        </ActionPlanTextWrapper>
      )}
      <ContentContainer>
        <ContentSection dangerouslySetInnerHTML={{ __html: data.body1 }} />
        {data.body2 && (
          <ContentSection dangerouslySetInnerHTML={{ __html: data.body2 }} />
        )}
        {data.body3 && (
          <ContentSection dangerouslySetInnerHTML={{ __html: data.body3 }} />
        )}

        {data.body4 && (
          <ContentSection dangerouslySetInnerHTML={{ __html: data.body4 }} />
        )}
      </ContentContainer>
    </PageWrapper>
  )
}

export default CenteredContentScreen
