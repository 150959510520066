import styled from "styled-components"
import { device } from "utils/breakpoints.js"

export const ProgressDotsContainer = styled.div`
  display: inline-grid;
  grid-auto-flow: column;
  grid-gap: 5px;

  @media ${device.desktop} {
    grid-gap: 16px;
  }
`

export const Dot = styled.span`
  width: 18px;
  height: 18px;
  border: ${props =>
    props.current ? "2px solid white" : "2px solid transparent"};
  border-radius: 50%;
  position: relative;
  opacity: ${props => (props.done || props.current ? 1 : 0.3)};
  transition: 100ms;
  ::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transition: 100ms;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background: #fff;
    transform: translate3d(-50%, -50%, 0);
  }

  ${props =>
    props.done &&
    `
    ::after {
		content: '';
		position: absolute;
		top: 50%;
		left: 50%;
		transition: 100ms;
    width: 5px;
    height: 9px;
    border-radius: 0%;
    background:none;
		border: solid white;
		border-width: 0 2px 2px 0;
		transform: translate3d(-50%, -60%, 0) rotate(45deg);
	}
  `};
`
