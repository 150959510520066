import styled from "styled-components"
import { device } from "utils/breakpoints.js"

export const TextContainer = styled.div`
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  padding-bottom: 23px;
  max-width: 1200px;
  margin-bottom: 30px;

  @media ${device.desktop} {
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-gap: 35px;
    justify-content: flex-start;
    margin: 0 auto;
    padding-bottom: 28px;
  }
`
export const PageTitle = styled.h2`
  font-size: 32px;
  margin-bottom: 26px;
  letter-spacing: -0.33px;
  line-height: 120%;
  flex-shrink: 0;

  @media ${device.tablet} {
    font-size: 38px;
  }
  @media ${device.desktop} {
    font-size: 48px;
    letter-spacing: -0.5px;
    margin-bottom: 0;
  }
`

export const StandardGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  max-width: ${props => props.theme.bodyMaxWidth};
  margin: 0 auto;
  align-items: center;
  border: 1px dashed hotpink;
  background-color: transparent;

  @media ${device.desktop} {
    grid-template-columns: 1fr 1fr;
    grid-gap: 50px;
  }
`

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 18px;
  letter-spacing: -0.25px;
  line-height: 150%;

  @media ${device.desktop} {
    font-size: 20px;
    letter-spacing: -0.15px;
    /* max-width: 678px; */
    /* width: 58%; */
  }

  span {
    font-size: 14px;
    letter-spacing: -0.09px;
    line-height: 30px;
    margin-top: 20px;

    @media ${device.desktop} {
      font-size: 16px;
      letter-spacing: -0.1px;
      line-height: 30px;
      margin-top: 15px;
    }
  }
  p {
    font-size: 18px;

    @media ${device.desktop} {
      font-size: 24px;
    }
    span {
      font-weight: 600;
    }
  }
`

export const BadgetTextWrapper = styled(TextWrapper)`
  display: block;
  text-align: left;

  @media ${device.desktop} {
    margin: 32px auto;
    margin-bottom: 0px;
    text-align: center;
    width: 100%;
  }
`
export const Badget = styled(PageTitle)`
  font-weight: 600;
  font-size: 20px;
  text-align: left;
  letter-spacing: -0.12px;
  line-height: 24px;
  margin-bottom: 16px;

  @media ${device.tablet} {
    font-size: 24px;
  }
  @media ${device.desktop} {
    font-size: 28px;
    width: 100%;
    margin-bottom: 14px;
    text-align: center;
  }
`

export const Item = styled.div`
  background: #ffffff;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  margin-bottom: ${props => props.two & "-20px"};
`

export const ItemsContainer = styled.div`
  margin: 30px auto;
  margin-bottom: 0;

  @media ${device.desktop} {
    max-width: ${props =>
      props.internationalPopUp ? "1200px" : props.two ? `784px` : "1200px"};

    /* ${Item} {
      margin-bottom: ${props => props.two && "-15px"};
    } */
  }
`

export const InternationalInfo = styled(Item)`
  margin: 0;
  /* grid-row: span 1 / 2; */
  padding: 15px 20px;
  display: flex;
  align-items: center;

  @media ${device.tablet} {
    grid-column: span 1 / 3;
    grid-row: span 2 / 2;
    padding: 15px 30px;
  }

  @media ${device.desktop} {
    grid-column: span 1 / 4;
    height: 158px;
    grid-row: span 3 / 2;
  }

  p {
    color: #4a5a80;
    font-size: 14px;

    @media ${device.tablet} {
      font-size: 16px;
    }
  }
  button {
    padding: 0;
    outline: 0;
    text-decoration: underline;
    background: none;
    border: none;
    color: #007eae;
    cursor: pointer;
    font-weight: 600;
  }

  span {
    font-weight: 600;
  }
`
