import React, { useRef, useState, useEffect, useContext } from "react"
import {
  ButtonsContainer,
  CardsContainer,
  CardsWrapper,
  CardsList,
  AcceptBtn,
  RejectBtn,
  RatherNotAnswerBtn,
  DoneModule,
} from "./style"
import { Card } from "./Card"
import { ProgressDots } from "components/ProgressDots"
import { UserContext } from "context/UserContext.js"
import { FlowContext } from "context/FlowContext.js"
// import { PopUpContext } from "context/PopUpContext"
import Checked from "images/finished-check.png"
const Cards = ({
  cards,
  numberOfCardsToShow,
  big,
  numbered,
  slider,
  numberedScale,
  yesNoPadding,
  compositeScale,
  highCards,
  id,
}) => {
  const cardsListRef = useRef(null)
  const [currentCard, setCurrentCard] = useState(0)
  const [results, setResults] = useState([])
  const [buttonsDisabled, setButtonsDisabled] = useState(false)
  const [cardsCompleted, setCardsCompleted] = useState(false)

  const { storeResponse } = useContext(UserContext)
  const { nextMove } = useContext(FlowContext)

  const nextCard = (type, value) => {
    const cards = cardsListRef.current.children
    const firstCard = cards[0]
    const secondCard = cards[1]

    const lastCard = cards[numberOfCardsToShow]
    firstCard.classList.add(type)
    secondCard && secondCard.classList.add("opaque")

    setCurrentCard(currentCard + 1)

    setButtonsDisabled(true)

    setResults([...results, type === "confirm" ? value : type])

    setTimeout(() => {
      firstCard.remove()

      if (cards.length >= numberOfCardsToShow) {
        lastCard.classList.remove("hide")
      }
      secondCard && secondCard.classList.remove("opaque")
    }, 500)

    setTimeout(() => {
      setButtonsDisabled(false)
    }, 1000)
  }

  useEffect(() => {
    storeResponse(id, results)
  }, [results])

  useEffect(() => {
    if (currentCard === cards.length) {
      setCardsCompleted(true)
    }
  }, [currentCard])

  return (
    <CardsContainer
      slider={slider}
      numberedScale={numberedScale}
      compositeScale={compositeScale}
      yesNoPadding={yesNoPadding}
    >
      <CardsWrapper>
        <CardsList
          ref={cardsListRef}
          big={big}
          slider={slider}
          numberedScale={numberedScale}
          compositeScale={compositeScale}
          highCards={highCards}
        >
          {cards.map((card, index) => {
            return (
              <Card
                big={big}
                numbered={numbered}
                slider={slider}
                numberedScale={numberedScale}
                compositeScale={compositeScale}
                className={index >= numberOfCardsToShow && "hide"}
                key={index}
                number={index + 1}
                totalCards={cards.length}
                primaryText={card.primaryText}
                secondaryText={card.secondaryText}
                list={card.list}
                nextCard={nextCard}
                options={card.options}
                buttonsDisabled={buttonsDisabled}
              />
            )
          })}

          <DoneModule cardsCompleted={cardsCompleted}>
            <h3>Thanks for completing this exercise.</h3>
            <p>
              Click '
              <button onClick={() => nextMove()} className="continue">
                Continue
              </button>
              ' to move to the next section
            </p>

            <img src={Checked} alt="" />
          </DoneModule>
        </CardsList>

        {slider || numberedScale || compositeScale ? null : (
          <ButtonsContainer cardsCompleted={cardsCompleted}>
            <RejectBtn
              disabled={buttonsDisabled || results.length === cards.length}
              label="No"
              onClick={() => nextCard("no")}
            />
            <AcceptBtn
              disabled={buttonsDisabled || results.length === cards.length}
              label="Yes"
              onClick={() => nextCard("yes")}
            />
          </ButtonsContainer>
        )}
      </CardsWrapper>

      {slider || numberedScale || compositeScale ? null : (
        <RatherNotAnswerBtn
          cardsCompleted={cardsCompleted}
          disabled={buttonsDisabled || results.length === cards.length}
          label="I'd rather not answer"
          onClick={() => nextCard("ratherNotAnswer")}
        />
      )}

      {!compositeScale && (
        <ProgressDots currentPosition={currentCard} items={cards} />
      )}
    </CardsContainer>
  )
}

export default Cards
