import React, { useState, useEffect } from "react"
import getColor from "helpers/getColor"
// import { getTrackBackground } from "react-range"

import {
  StyledRange,
  RangeContainer,
  Track,
  Thumb,
  LabelContainer,
  Label,
} from "./style"

const STEP = 1
const MIN = 0
const MAX = 100
const color1 = "de0067"
const color2 = "007eae"

const RangeSlider = props => {
  const [values, setValues] = useState([0])
  const [thumbColor, setThumbColor] = useState("de0067")

  useEffect(() => {
    props.setCardValue(values)
    const chosenColor = getColor(color1, color2, values[0] / 100)
    setThumbColor(chosenColor)
  }, [values])

  return (
    <div
      className="range-wrapper"
      style={{
        display: "flex",
        justifyContent: "center",
        flexWrap: "wrap",
        width: "100%",
        pointerEvents: props.disabled && "none",
      }}
    >
      <StyledRange
        values={values}
        step={STEP}
        min={MIN}
        max={MAX}
        onChange={values => setValues(values)}
        renderTrack={({ props, children }) => (
          <RangeContainer
            onMouseDown={props.onMouseDown}
            onTouchStart={props.onTouchStart}
            style={{
              ...props.style,
              height: "30px",
              display: "flex",
              width: "100%",
            }}
          >
            <Track
              ref={props.ref}
              style={{
                height: "8px",
                width: "100%",
                borderRadius: "4px",
                // background: getTrackBackground({
                //   values: values,
                //   colors: ["#548BF4", "#ccc"],
                //   min: MIN,
                //   max: MAX,
                // }),
                alignSelf: "center",
              }}
            >
              {children}
            </Track>
          </RangeContainer>
        )}
        renderThumb={({ props, isDragged }) => (
          <Thumb
            {...props}
            style={{
              // ...props.style,
              // height: "42px",
              // width: "42px",
              // borderRadius: "4px",
              backgroundColor: `#${thumbColor}`,
              // display: "flex",
              // justifyContent: "center",
              // alignItems: "center",
              // boxShadow: "0px 2px 6px #AAA",
            }}
          >
            {/* <div
              style={{
                height: "16px",
                width: "5px",
                backgroundColor: isDragged ? "#548BF4" : "#CCC",
              }}
            /> */}
          </Thumb>
        )}
      />

      {props.showOutput && (
        <output style={{ marginTop: "10px", color: "teal" }} id="output">
          {values[0].toFixed(1)}
        </output>
      )}

      {props.options && (
        <LabelContainer>
          {props.options.map((label, index) => {
            return <Label key={index}>{label}</Label>
          })}
        </LabelContainer>
      )}
    </div>
  )
}

export default RangeSlider
