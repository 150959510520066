import styled from "styled-components"
import { device } from "utils/breakpoints.js"
import { Label } from "components/RangeSlider/style"
export const NumberedScaleContainer = styled.div`
  margin-top: 24px;
  margin-bottom: 5px;
  position: relative;
  @media ${device.tablet} {
    margin-top: 0px;
    margin-bottom: 10px;
  }
`

export const ScaleContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 18px;
  grid-row-gap: 44px;

  @media ${device.tablet} {
    grid-template-columns: repeat(11, 1fr);
    grid-column-gap: 8px;
  }
`

export const Number = styled.button`
  padding: 0;
  border: none;
  display: flex;
  outline: none;
  justify-content: center;
  align-items: center;
  height: 47px;
  width: 47px;
  border-radius: 50%;
  font-family: "Azo Sans", "Arial", "sans-serif";

  background: #dd0067;
  position: relative;
  cursor: pointer;

  :hover {
    :before {
      opacity: 1;
    }
  }

  :before {
    border: 2px solid #dd0067;
  }

  :nth-of-type(2) {
    background: #c40f70;
    :before {
      border: 2px solid #c40f70;
    }
  }

  :nth-of-type(3) {
    background: #ac1c77;
    :before {
      border: 2px solid #ac1c77;
    }
  }

  :nth-of-type(4) {
    background: #a3227b;
    :before {
      border: 2px solid #a3227b;
    }
  }

  :nth-of-type(5) {
    background: #823484;
    :before {
      border: 2px solid #823484;
    }
  }

  :nth-of-type(6) {
    background: #703e8a;
    :before {
      border: 2px solid #703e8a;
    }
  }

  :nth-of-type(7) {
    background: #5c4a91;
    :before {
      border: 2px solid #5c4a91;
    }
  }

  :nth-of-type(8) {
    background: #445798;
    :before {
      border: 2px solid #445798;
    }
  }

  :nth-of-type(9) {
    background: #2968a2;
    :before {
      border: 2px solid #2968a2;
    }
  }

  :nth-of-type(10) {
    background: #1473a8;
    :before {
      border: 2px solid #1473a8;
    }
  }

  :nth-of-type(11) {
    background: #027eae;
    :before {
      border: 2px solid #027eae;
    }
  }

  @media (min-width: 360px) {
    height: 56px;
    width: 56px;
  }

  @media ${device.tablet} {
    height: 36px;
    width: 36px;
  }

  span {
    font-weight: 600;
    font-size: 24px;
    color: white;

    @media ${device.tablet} {
      font-size: 18px;
    }
  }

  ::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    height: calc(100% + 8px);
    width: calc(100% + 8px);
    background: white;
    border-radius: 50%;
    opacity: ${props => (props.selected ? 1 : 0)};
    transition: 90ms;
    z-index: -1;
  }
`

export const LabelsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 44px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  justify-items: flex-start;
  margin-top: -26px;
  align-items: flex-start;

  @media ${device.tablet} {
    position: static;
    grid-template-columns: 126px 146px 126px;
    justify-content: space-between;
    align-items: center;
    margin-top: 0px;
    margin-bottom: 10px;
    height: auto;
  }
`

export const NumberLabel = styled(Label)`
  font-size: 14px;
`
