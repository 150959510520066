import React from "react"
import { BarGraphContainer, ProgressTrackKey, ProgressKeyLabels } from "./style"
import Bar from "./Bar"

const BarGraph = ({ workAverage, partnerAverage, friendAverage }) => {
  const barData = [
    { label: "Work", value: 100 - workAverage },
    { label: "Partner", value: 100 - partnerAverage },
    { label: "Friends", value: 100 - friendAverage },
  ]
  return (
    <>
      <BarGraphContainer>
        {barData.map((bar, index) => {
          return <Bar key={index} label={bar.label} value={bar.value} />
        })}
      </BarGraphContainer>
      <ProgressTrackKey />
      <ProgressKeyLabels>
        <span>Very easy</span>
        <span>Very difficult</span>
      </ProgressKeyLabels>
    </>
  )
}

export default BarGraph
