import React, { useContext } from "react"
import { IntroGrid, ButtonsBlock, IntroButton } from "./style"
import { PageWrapper } from "components/PageWrapper"
import { PageTitle, TextWrapper } from "../reusableComponents"
import { UserContext } from "context/UserContext.js"
import { FlowContext } from "context/FlowContext.js"
// import { useSpring, animated } from "react-spring"

const IntroScreen = () => {
  const { storeBranch } = useContext(UserContext)
  const { nextMove } = useContext(FlowContext)

  // const fadeIn = useSpring({
  //   from: {
  //     opacity: 0,
  //   },
  //   to: {
  //     opacity: 1,
  //   },
  //   config: {
  //     duration: 1000,
  //   },
  // })

  // const AnimatedPageWrapper = animated(PageWrapper)

  return (
    <PageWrapper noPagination>
      <IntroGrid className="intro">
        <TextWrapper>
          <PageTitle>Welcome to MySafety</PageTitle>
          <p>
            This website is designed to help James Cook University students
            who are worried about a relationship or a sexual experience, or who
            are concerned about a friend.
          </p>
        </TextWrapper>
        <ButtonsBlock>
          <TextWrapper>
            <p className="margin">Are you worried about…</p>
          </TextWrapper>
          <IntroButton
            label="Something that happened to you"
            onClick={() => {
              storeBranch("victim")
              nextMove()
            }}
          />
          <IntroButton
            label="Something you did to someone else"
            onClick={() => {
              storeBranch("perpetrator")
              nextMove()
            }}
          />
          <IntroButton
            label="A friend"
            onClick={() => {
              // storeBranch("friend")
              storeBranch("friend")
              nextMove()
            }}
          />
        </ButtonsBlock>
      </IntroGrid>
    </PageWrapper>
  )
}

export default IntroScreen
