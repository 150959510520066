const arrSum = arr => {
  const filteredArr = arr.filter(item => {
    return parseInt(item) == item
  })
  return filteredArr.reduce(function(a, b) {
    return a + (b || 0)
  }, 0)
}

export default arrSum
