import React, { useState, useEffect } from "react"
import {
  NumberedScaleContainer,
  ScaleContainer,
  LabelsContainer,
  NumberLabel,
} from "./style"
import NumberButton from "./NumberButton.js"

const numbers = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]

const NumberedScale = ({ options, setCardValue }) => {
  const [selectedValue, setSelectedValue] = useState(5)

  useEffect(() => {
    setCardValue(selectedValue)
  }, [selectedValue])
  return (
    <NumberedScaleContainer>
      <LabelsContainer>
        {options.map((label, index) => {
          return <NumberLabel key={index}>{label}</NumberLabel>
        })}
      </LabelsContainer>

      <ScaleContainer>
        {numbers.map((num, index) => {
          return (
            <NumberButton
              key={index}
              value={num}
              selected={selectedValue === num}
              setSelectedValue={setSelectedValue}
            />
          )
        })}
      </ScaleContainer>
    </NumberedScaleContainer>
  )
}

export default NumberedScale
