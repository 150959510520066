import styled from "styled-components"
import { device } from "utils/breakpoints.js"
import { Section } from "components/PopUp/LongFormPopUpModule/style"
export const ContentContainer = styled.div`
  background: #ffffff;
  box-shadow: 0 10px 25px 0 rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  padding: 0 20px;
  max-width: 785px;
  margin: 0 auto;

  @media ${device.desktop} {
    padding: 0 40px;
  }
`

export const ContentSection = styled(Section)`
  a {
    cursor: pointer;
    :hover {
      text-decoration: none;
    }
  }
`
