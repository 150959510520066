import styled from "styled-components"
import { device } from "utils/breakpoints.js"

import { StandardGrid, TextWrapper, PageTitle } from "../reusableComponents.js"

export const Container = styled(StandardGrid)``
export const TextSection = styled(TextWrapper)`
  @media ${device.desktop} {
    align-self: flex-start;
  }
`

export const Title = styled(PageTitle)``

export const CardsSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`
