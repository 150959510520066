import React from "react"
import { Container, TextSection, CardsSection } from "./style"
import { PageWrapper } from "../PageWrapper"
import { Cards } from "../Cards"

const YesNoCardsScreen = ({ data, id }) => {
  return (
    <PageWrapper>
      <Container>
        <TextSection dangerouslySetInnerHTML={{ __html: data.textLeft }} />
        <CardsSection>
          <TextSection dangerouslySetInnerHTML={{ __html: data.textRight }} />
          <Cards
            highCards={data.highCards}
            yesNoPadding={data.textRight}
            numbered
            cards={data.cardsData}
            numberOfCardsToShow={4}
            id={id}
          />
        </CardsSection>
      </Container>
    </PageWrapper>
  )
}

export default YesNoCardsScreen
