import React, { useState, useEffect } from "react"
import { CompositeScaleContainer, OptionsContainer } from "./style"
import CompositeButton from "./CompositeButton.js"

const options = [
  "Daily",
  "Once a week",
  "Once a month",
  "Several times",
  "Only once",
  "Never",
]

const CompositeScale = ({ nextCard, unansweredScale }) => {
  const [selectedValue, setSelectedValue] = useState("")

  useEffect(() => {
    if (selectedValue.length && !unansweredScale) {
      nextCard("confirm", selectedValue)
    }
  }, [selectedValue])
  return (
    <CompositeScaleContainer>
      <OptionsContainer>
        {options.map((option, index) => {
          return (
            <CompositeButton
              key={index}
              value={option}
              selected={selectedValue === option}
              setSelectedValue={setSelectedValue}
            />
          )
        })}
      </OptionsContainer>
    </CompositeScaleContainer>
  )
}

export default CompositeScale
