import styled from "styled-components"
import { device } from "utils/breakpoints.js"

export const VideoContainer = styled.div`
  display: flex;
  flex-direction: ${props => (props.small ? "row" : "column")};
  box-shadow: 0 10px 25px 0 rgba(0, 0, 0, 0.15);
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  position: relative;
`

export const ImageContainer = styled.div`
  padding-top: ${props => (props.small ? "34% " : "56.307%")};
  position: relative;
  width: ${props => (props.small ? "33.3333%" : "100%")};
  flex-shrink: 0;
  border-radius: ${props => (props.small ? "5px 0 0 5px" : " 5px 5px 0 0")};

  svg.play {
    position: absolute;
    top: 50%;
    background: #ffffff;
    box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.15);
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    background: white;
    width: 42px;
    height: 42px;
    border-radius: 50%;
    opacity: 1;
    transition: 170ms;

    @media ${device.tablet} {
      width: 60px;
      height: 60px;
    }

    ${VideoContainer}:hover & {
      opacity: 0.8;
    }
  }

  img {
    position: absolute;
    top: 0;
    left: 0;
    object-fit:cover;
    height: 100%;
    width: 100%;
    z-index: -1;
  }
`

export const InfoContainer = styled.div`
  background: ${props => props.theme.purple2};
  padding: ${props => (props.small ? "0 15px" : "13px")};
  font-size: ${props => (props.small ? "14px" : "12px")};
  border-radius: ${props => (props.small ? "0px 5px 5px 0px" : " 0 0 5px 5px")};
  width: 100%;
  display: flex;
  flex-direction: ${props => (props.small ? "column" : "row")};
  justify-content: ${props => (props.small ? "center" : null)};
  line-height: 100%;
  span {
    margin-right: 4px;
    margin-bottom: ${props => props.small && "5px"};

    @media ${device.tablet} {
      margin-bottom: ${props => props.small && "14px"};
      margin-right: 7px;
    }
  }

  @media (min-width: 450px) {
    font-size: ${props => (props.small ? "18px" : "18px")};
    padding: ${props => (props.small ? "33px " : "15px 20px")};
  }
  @media (min-width: 550px) {
    padding: ${props => (props.small ? "33px " : "15px 20px")};
    font-size: ${props => (props.small ? "22px" : "18px")};
  }
  /* @media ${device.tablet} {
    padding: ${props => (props.small ? "33px " : "15px 20px")};
  } */
  p {
    display: ${props => (props.small ? "block" : "inline")};
    line-height: ${props => props.small && "140%"};
  }
`

export const PlayButton = styled.button`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: none;
  border: none;
  cursor: pointer;
  z-index: 1;
`
