import styled from "styled-components"
import { device } from "utils/breakpoints.js"
import { Track } from "components/RangeSlider/style"
export const BarGraphContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 10px;
  margin-bottom: 22px;
  width: 100%;
  @media ${device.desktop} {
    margin-bottom: 27px;
  }
`

export const BarContainer = styled.div`
  height: 40px;
  width: 100%;
  background: #f5f4f6;
  border-radius: 5px;
  position: relative;
  @media ${device.desktop} {
    height: 60px;
  }
`

export const ValueIndicator = styled.div`
  border-radius: 5px;
  background: #b31975;
  height: 100%;
  width: 0%;
  transition: width 850ms ease-in;
`

export const BarLabelContainer = styled.div`
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
`
export const BarLabel = styled.h4`
  font-size: 14px;
  color: white;
  position: absolute;
  top: 50%;
  font-family: "Adelle", "Times New Roman", "serif";
  left: 15px;
  transform: translate3d(0%, -42%, 0);
  @media ${device.desktop} {
    font-size: 20px;
    left: 20px;
  }
`
export const ColoredBarLabel = styled(BarLabel)``
export const ProgressTrackKey = styled(Track)`
  width: 100%;
  height: 8px;
  transform: scale(-1);
  margin-bottom: 17px;
`
export const ProgressKeyLabels = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;

  span {
    font-size: 14px;
    color: #007eae;

    :last-of-type {
      color: #dd0168;
    }
  }
`
