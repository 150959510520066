import styled from "styled-components"
import { device } from "utils/breakpoints.js"
import { StandardGrid } from "components/reusableComponents"
import { CardContainer, PrimaryCardText } from "components/Cards/Card/style"
export const ContentGrid = styled(StandardGrid)`
  /* @media ${device.tablet} {
    grid-template-columns: 1fr 1fr;
  } */
`

export const FeedbackBlock = styled(CardContainer)`
  position: static;
  left: auto;
  top: auto;
  transform: none !important;
  opacity: 1 !important;

  @media ${device.desktop} {
    padding: 45px 40px;
  }
`

export const InfoText = styled(PrimaryCardText)`
  text-align: left;
  width: 100%;
  @media ${device.tablet} {
    font-size: 20px;
  }
`
