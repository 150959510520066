import React from "react"
import { Number } from "./style"

const NumberButton = ({ value, selected, setSelectedValue }) => {
  return (
    <Number
      selected={selected}
      onFocus={() => {
        setSelectedValue(value)
      }}
      onClick={() => {
        setSelectedValue(value)
      }}
    >
      <span>{value}</span>
    </Number>
  )
}

export default NumberButton
