import styled from "styled-components"
import { device } from "utils/breakpoints.js"
import { Range } from "react-range"

export const StyledRange = styled(Range)``

export const RangeContainer = styled.div``

export const Track = styled.div`
  background-image: linear-gradient(90deg, #de0067 0%, #007eae 100%);
  border-radius: 5px;
`

export const Thumb = styled.div`
  background: #de0c6f;
  border: 2px solid #ffffff;
  border-radius: 50%;
  height: 30px;
  width: 30px;
`

export const LabelContainer = styled.div`
  width: 100%;
  margin-top: 10px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 6px;
  justify-content: space-between;
  align-items:center;
  @media ${device.tablet} {
    grid-template-columns: 126px 146px 126px;

    margin-top: 10px;
  }
`

export const Label = styled.span`
  font-size: 12px;
  @media ${device.tablet} {
    font-size: 14px;
  }

  :first-of-type {
    text-align: left;
    color: #dd0168;
  }

  :nth-of-type(2) {
    text-align: center;
    color: #733c89;
  }

  :nth-of-type(3) {
    text-align: right;
    color: #007eae;
  }
`
