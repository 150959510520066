import React from "react"
import { SectionContainer, ExtendedSlider } from "./style"

const ScaleList = ({ title, prompt, questions, handleCasChange }) => {
  return (
    <SectionContainer>
      <h3>{title}</h3>
      <p>{prompt}</p>
      {questions.map((question, index) => {
        return (
          <ExtendedSlider
            key={index}
            onChange={handleCasChange}
            compositeScale
            unansweredScale
            primaryText={question.primaryText}
          />
        )
      })}
    </SectionContainer>
  )
}

export default ScaleList
