import React from "react"
import { CompositeOptionButton, CompositeCircle, CompositeLabel } from "./style"

const CompositeButton = ({ value, selected, setSelectedValue }) => {
  return (
    <CompositeOptionButton
      selected={selected}
      onFocus={() => {
        setSelectedValue(value)
      }}
      onClick={() => {
        setSelectedValue(value)
      }}
    >
      <CompositeCircle />
      <CompositeLabel>{value}</CompositeLabel>
    </CompositeOptionButton>
  )
}

export default CompositeButton
