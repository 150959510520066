import styled from "styled-components"
import { device } from "utils/breakpoints.js"
import { StandardGrid, TextWrapper } from "../reusableComponents.js"
import { Button } from "components/Button"
import { IconWrapper } from "../Button/style.js"
import { PageWrapper } from "../../components/PageWrapper"

export const ScreenContainer = styled(StandardGrid)`
  @media ${device.large} {
    grid-gap: 60px !important;
  }
`

export const TextSection = styled(TextWrapper)`
  p,
  h2 {
    text-align: center;
  }
  @media ${device.desktop} {
    p,
    h2 {
      text-align: left;
    }
  }
`

export const VideosWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr;
  grid-gap: 20px;

  @media ${device.tablet} {
    grid-gap: 40px;
  }
`

export const Warning = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 700px;
  margin: 0 auto;

  @media ${device.tablet} {
    flex-direction: row;
  }

  svg {
    width: 150px;
    margin-top: 5px;

    @media ${device.tablet} {
      width: 200px;
    }

    @media ${device.desktop} {
      width: 265px;
    }
  }

  p {
    font-size: 16px;
    color: #ffffff;
    letter-spacing: 0;
    line-height: 24px;
    text-align: left;
  }
`
export const ViewButton = styled(Button)`
  font-size: 18px;
  font-weight: 400;
  text-decoration: underline;
  padding: 0;
  margin-top: 20px;

  @media ${device.desktop} {
    font-size: 24px;
    margin-top: 30px;
    align-self: flex-start;
  }
  ${IconWrapper} {
    border-radius: 50%;
    background: #3b10b1;
    height: 24px;
    width: 24px;
    margin-right: 10px;
    svg {
      height: 9px;
      transform: scale(-1);
      g,
      path {
        fill: white;
      }
    }

    @media ${device.desktop} {
      height: 32px;
      width: 32px;
      margin-right: 18px;

      svg {
        height: 18px;
      }
    }
  }
`
