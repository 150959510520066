import styled from "styled-components"
import { device } from "utils/breakpoints.js"
// import { Label } from "components/RangeSlider/style"
export const CompositeScaleContainer = styled.div`
  width: 100%;
  margin-bottom: 10px;
  @media ${device.tablet} {
    margin-top: 10px;
    margin-bottom: 15px;
  }
`

export const OptionsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-content: space-between;
  grid-column-gap: 10px;
  grid-row-gap: 33px;
  @media ${device.tablet} {
    grid-column-gap: 0px;

    grid-template-columns: repeat(6, 1fr);
  }
`

export const CompositeCircle = styled.span`
  padding: 0;
  outline: none;
  justify-content: center;
  align-items: center;
  height: 36px;
  width: 36px;
  border-radius: 50%;
  background: none;
  position: relative;
  cursor: pointer;
  border: 7px solid #dd0067;
  margin-bottom: 7px;

  @media ${device.tablet} {
    margin-bottom: 13px;
  }
  ::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    background: #dd0067;
    transform: translate3d(-50%, -50%, 0);
    height: 12px;
    width: 12px;
    border-radius: 50%;
    opacity: 0;
    transition: 60ms;
    z-index: -1;
  }
`

export const CompositeLabel = styled.span`
  font-size: 14px;
  color: #dd0067;

  @media ${device.tablet} {
    font-size: 16px;
  }
`

export const CompositeOptionButton = styled.button`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  background: none;
  cursor: pointer;
  border: none;
  padding: 0;
  outline: none;

  :nth-of-type(2) {
    ${CompositeCircle} {
      border: 7px solid #a3227b;
      :before {
        background: #a3227b;
      }
    }

    ${CompositeLabel} {
      color: #a3227b;
    }
  }

  :nth-of-type(3) {
    ${CompositeCircle} {
      border: 7px solid #703e8a;
      :before {
        background: #703e8a;
      }
    }

    ${CompositeLabel} {
      color: #703e8a;
    }
  }

  :nth-of-type(4) {
    ${CompositeCircle} {
      border: 7px solid #5c4a91;
      :before {
        background: #5c4a91;
      }
    }
    ${CompositeLabel} {
      color: #5c4a91;
    }
  }

  :nth-of-type(5) {
    ${CompositeCircle} {
      border: 7px solid #2968a2;
      :before {
        background: #2968a2;
      }
    }
    ${CompositeLabel} {
      color: #2968a2;
    }
  }

  :nth-of-type(6) {
    ${CompositeCircle} {
      border: 7px solid #027eae;
      :before {
        background: #027eae;
      }
    }
    ${CompositeLabel} {
      color: #027eae;
    }
  }

  :hover {
    ${CompositeCircle} {
      :before {
        opacity: 0.6;
      }
    }
  }
  ${CompositeCircle} {
    :before {
      opacity: ${props => (props.selected ? `1 !important` : 0)};
    }
  }
`
