import React, { useState, useEffect } from "react"
import {
  CardContainer,
  Counter,
  PrimaryCardText,
  SecondaryCardText,
  SkipCardBtn,
  // CardTextArea,
  List,
  RangeButtonsContainer,
  RangeRatherNotSay,
  ConfirmBtn,
} from "./style"
import { RangeSlider } from "components/RangeSlider"
import { NumberedScale } from "components/NumberedScale"
import { CompositeScale } from "components/CompositeScale"

const Card = ({
  number,
  className,
  totalCards,
  primaryText,
  secondaryText,
  nextCard,
  big,
  numbered,
  slider,
  numberedScale,
  compositeScale,
  options,
  list,
  onChange,
  unansweredScale,
  buttonsDisabled,
}) => {
  const formattedNumber = value => {
    return ("0" + value).slice(-2)
  }

  const [cardValue, setCardValue] = useState(null)

  useEffect(() => {
    if (onChange && cardValue) {
      onChange(primaryText, cardValue)
    }
  }, [cardValue])

  return (
    <CardContainer
      slider={slider}
      numberedScale={numberedScale}
      compositeScale={compositeScale}
      className={className}
      big={big}
    >
      {numbered && (
        <Counter big={big}>
          <h5 className="current">{formattedNumber(number)}</h5>
          <h5>/ {formattedNumber(totalCards)}</h5>
        </Counter>
      )}
      <PrimaryCardText
        slider={slider}
        numberedScale={numberedScale}
        compositeScale={compositeScale}
        big={big}
      >
        {primaryText}
      </PrimaryCardText>

      {secondaryText && (
        <SecondaryCardText> {secondaryText}:</SecondaryCardText>
      )}

      {list && (
        <List>
          {list.map((item, index) => {
            return <li key={index}>{item}</li>
          })}
        </List>
      )}

      {slider && !numberedScale && (
        <RangeSlider
          setCardValue={setCardValue}
          options={options}
          disabled={buttonsDisabled}
        />
      )}

      {numberedScale && (
        <NumberedScale
          options={options}
          setCardValue={setCardValue}
          nextCard={nextCard}
        />
      )}

      {compositeScale && (
        <CompositeScale
          options={options}
          setCardValue={setCardValue}
          nextCard={nextCard}
          cardValue={cardValue}
          unansweredScale={unansweredScale}
        />
      )}

      {(slider || numberedScale || compositeScale) && (
        <RangeButtonsContainer className="buttons-container">
          <RangeRatherNotSay
            cas={compositeScale}
            disabled={buttonsDisabled}
            label="I'd rather not answer"
            onClick={() => nextCard("ratherNotAnswer")}
          />
          {!compositeScale && (
            <ConfirmBtn
              label="confirm?"
              disabled={buttonsDisabled}
              onClick={() =>
                nextCard("confirm", slider ? cardValue[0] : cardValue)
              }
            />
          )}
        </RangeButtonsContainer>
      )}

      <SkipCardBtn
        disabled={buttonsDisabled}
        label="SKIP"
        className="skip"
        noBackground
        onClick={() => nextCard("skip")}
      />
    </CardContainer>
  )
}

export default Card
