import React, { useState, useContext, useEffect } from "react"
import {
  PaginationContainer,
  PreviousButtonContainer,
  NextButtonContainer,
  PreviousButton,
  NextButton,
} from "./style"
import { Icon } from "utils/getIcon"
import { FlowContext } from "context/FlowContext"
import getOS from "helpers/getOS"
import { PopUpContext } from "context/PopUpContext.js"

const Pagination = ({ hide, disabled }) => {
  const singleArrow = <Icon name="arrow" />
  const { nextMove, goBack } = useContext(FlowContext)
  const { isPopUpOpen, popUpType } = useContext(PopUpContext)
  const [shove, setShove] = useState(false)


  const operatingSystem = getOS()
  const windows = operatingSystem === "Windows"

  useEffect(() => {
    if (
      windows &&
      isPopUpOpen &&
      (popUpType === "longForm" || "getHelp" || "international")
    ) {
      setShove(true)
    } else {
      setShove(false)
    }
  }, [windows, isPopUpOpen, popUpType])

  // useEffect(() => {
  //   console.log("shove:", shove)
  // }, [shove])

  return (
    <PaginationContainer hide={hide}>
      <PreviousButtonContainer>
        <PreviousButton
          disabled={disabled || hide}
          label="Back"
          icon={singleArrow}
          onClick={() => goBack()}
          noBackground
        />
      </PreviousButtonContainer>
      <NextButtonContainer shove={shove}>
        <NextButton
          disabled={disabled || hide}
          label="Continue"
          icon={singleArrow}
          onClick={() => nextMove()}
          noBackground
          iconRight
        />
      </NextButtonContainer>
    </PaginationContainer>
  )
}

export default Pagination
