import React, { useContext, useState, useEffect } from "react"
import { PageWrapper } from "../PageWrapper"
import { Checkbox } from "../Checkbox"
// import { GenericList } from "../list"
import { UserContext } from "context/UserContext.js"
import { PopUpContext } from "context/PopUpContext.js"
import { Grid } from "../Grid"

import {
  TextContainer,
  PageTitle,
  TextWrapper,
  Badget,
  BadgetTextWrapper,
  ItemsContainer,
  Item,
  InternationalInfo,
} from "./style"

const CheckboxScreen = ({ data, id }) => {
  const [checked, setChecked] = useState([])
  const { storeResponse } = useContext(UserContext)
  const { togglePopUp } = useContext(PopUpContext)

  const internationalPopUp = data.internationalPopUp

  const two = data.columns === 2

  const toggleChecked = event => {
    const target = event.target
    const value = target.value
    const index = target.index
    const itemExists = checked.includes(value)

    if (itemExists) {
      const updatedCheckedItems = checked.filter(item => item !== value)
      setChecked([...updatedCheckedItems])
    } else {
      setChecked([...checked, value])
    }
  }

  useEffect(() => {
    storeResponse(id, [...checked])
  }, [checked])

  return (
    <PageWrapper>
      <TextContainer>
        <PageTitle>{data.heading}</PageTitle>
        <TextWrapper dangerouslySetInnerHTML={{ __html: data.textRight }} />
      </TextContainer>
      <div>
        {data.subheading && (
          <BadgetTextWrapper>
            <Badget>{data.subheading}</Badget>
            <span>Please choose all the options that apply.</span>
          </BadgetTextWrapper>
        )}
        <ItemsContainer two={two} internationalPopUp={internationalPopUp}>
          <Grid
            cols={[1, 2, internationalPopUp ? 3 : data.columns]}
            gap={[20, 30, two ? 30 : 44]}
          >
            {data.options.map((option, index) => {
              return (
                <Item key={index}>
                  <Checkbox
                    onChange={toggleChecked}
                    name={id}
                    value={option}
                    index={index}
                  />
                </Item>
              )
            })}

            {internationalPopUp && (
              <InternationalInfo>
                <p>
                  <button onClick={() => togglePopUp(true, "international")}>
                    Click here
                  </button>{" "}
                  if you are an <span>international student</span> and want to
                  find out about your rights in Australia after an unwanted
                  sexual experience.{" "}
                </p>
              </InternationalInfo>
            )}
          </Grid>
        </ItemsContainer>
      </div>
    </PageWrapper>
  )
}

export default CheckboxScreen
