const getColor = (color1, color2, percentage) => {
  const hex = x => {
    const hexValue = x.toString(16)
    return hexValue.length === 1 ? "0" + hexValue : hexValue
  }

  const r = Math.ceil(
    parseInt(color2.substring(0, 2), 16) * percentage +
      parseInt(color1.substring(0, 2), 16) * (1 - percentage)
  )
  const g = Math.ceil(
    parseInt(color2.substring(2, 4), 16) * percentage +
      parseInt(color1.substring(2, 4), 16) * (1 - percentage)
  )
  const b = Math.ceil(
    parseInt(color2.substring(4, 6), 16) * percentage +
      parseInt(color1.substring(4, 6), 16) * (1 - percentage)
  )

  return hex(r) + hex(g) + hex(b)
}
export default getColor
