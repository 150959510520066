import React, { useState, useContext, useEffect } from "react"
import {
  SkipButton,
  LargeRatherNotSay,
  Submit,
  ButtonsContainer,
} from "./style"
import { PageWrapper } from "components/PageWrapper"
import { PageTitle } from "../reusableComponents"
import { ActionPlanTextWrapper } from "components/ActionPlanScreen/style"
import { ReactComponent as Skip } from "images/icons/double-arrow-circle.svg"
import {
  CompositeScaleCardsData,
  TalkingAboutItYesNoCardsData,
} from "utils/victimRelationshipFlowData"
import CheckboxList from "./CheckboxList"
import ScaleList from "./ScaleList"
import { UserContext } from "context/UserContext.js"
import { FlowContext } from "context/FlowContext.js"
import { getCasScore } from "utils/getCasScore"

const UnansweredQuestionsScreen = () => {
  const { user, storeResponse, setUser } = useContext(UserContext)
  const { nextMove, getActionPlan } = useContext(FlowContext)
  const [checked, setChecked] = useState([])
  // console.log("TalkingAboutItYesNoCardsData:", TalkingAboutItYesNoCardsData)
  // console.log("CompositeScaleCardsData:", CompositeScaleCardsData)

  const [casResults, setCasResults] = useState([])

  const handleCasChange = (id, value) => {
    let updatedCasResults = casResults.filter(result => result.cardId !== id)
    setCasResults([...updatedCasResults, { cardId: id, value: value }])
  }

  const casResponses = user.responses.find(
    responses => responses.id === "2.1.2.1-partner-behaviours-composite-scales"
  )

  const riskAssessmentResponses = user.responses.find(
    responses => responses.id === "2.1.2.9-talking-about-it-yes-no-cards"
  )

  function getAllIndexes(arr, val) {
    var indexes = [],
      i
    for (i = 0; i < arr.length; i++) if (arr[i] === val) indexes.push(i)
    return indexes
  }

  const getUnansweredQuestions = (responses, options) => {
    const skippedIndices = getAllIndexes(responses, "skip")

    const skippedQuestions = skippedIndices.map(position => options[position])

    const remainingQuestions = options.slice(responses.length, options.length)

    return skippedQuestions.concat(remainingQuestions)
  }

  const UnansweredCasQuestions = getUnansweredQuestions(
    casResponses.results,
    CompositeScaleCardsData
  )

  const UnansweredRiskAssessmentQuestions = getUnansweredQuestions(
    riskAssessmentResponses.results,
    TalkingAboutItYesNoCardsData
  )

  // console.log("UnansweredCasQuestions:", UnansweredCasQuestions)
  // console.log(
  //   "UnansweredRiskAssessmentQuestions:",
  //   UnansweredRiskAssessmentQuestions
  // )

  const mergeResponses = (id, previouslyAnswered, newAnswers) => {
    storeResponse(id, [...previouslyAnswered, ...newAnswers])
  }

  const onSubmit = () => {
    const updatedCasScore = getCasScore(null, casResults)
    // console.log("updatedCasScore:", updatedCasScore)

    if (checked.length > 0 || updatedCasScore === "SC") {
      setUser({ ...user, ipvHR: true })
    }

    nextMove()
  }

  useEffect(() => {
    if (
      !UnansweredRiskAssessmentQuestions.length &&
      !UnansweredCasQuestions.length
    ) {
      getActionPlan()
    }
  }, [casResults])

  return (
    <PageWrapper>
      <ActionPlanTextWrapper>
        <PageTitle>Unanswered Questions</PageTitle>
        <p>
          Answering all questions helps us give you the most relevant and
          accurate advice. Please fill all the questions below or <Skip />
          <SkipButton noBackground label="skip" onClick={() => nextMove()} />.
        </p>
      </ActionPlanTextWrapper>

      {UnansweredRiskAssessmentQuestions.length > 0 && (
        <CheckboxList
          checked={checked}
          setChecked={setChecked}
          title="Talking About It"
          prompt="Please choose all the options that apply."
          questions={UnansweredRiskAssessmentQuestions}
          mergeResponses={mergeResponses}
        />
      )}

      {UnansweredCasQuestions.length > 0 && (
        <ScaleList
          title="Partner Behaviours"
          prompt="How often have you experienced these over the last 12 months"
          questions={UnansweredCasQuestions}
          id={"2.1.2.1-partner-behaviours-composite-scales"}
          handleCasChange={handleCasChange}
        />
      )}

      <ButtonsContainer>
        <LargeRatherNotSay
          label="I'd rather not answer these questions."
          onClick={() => nextMove()}
        />

        <Submit
          label="Submit answers"
          onClick={() => {
            onSubmit()
          }}
        />
      </ButtonsContainer>
    </PageWrapper>
  )
}

export default UnansweredQuestionsScreen
