import React from "react"
import { Container, TextSection, CardsSection } from "./style"
import { PageWrapper } from "../PageWrapper"
import { Cards } from "../Cards"

const BigYesNoCardsScreen = ({ data, id }) => {
  return (
    <PageWrapper>
      <Container>
        <TextSection dangerouslySetInnerHTML={{ __html: data.textLeft }} />
        <CardsSection>
          <Cards
            highCards={data.highCards}
            numbered
            cards={data.cardsData}
            numberOfCardsToShow={4}
            big
            id={id}
          />
        </CardsSection>
      </Container>
    </PageWrapper>
  )
}

export default BigYesNoCardsScreen
