import React, { useContext } from "react"
import {
  IntroGrid,
  ButtonsBlock,
  ButtonsWrapper,
  IntroButton,
} from "components/IntroScreen/style"
import { PageWrapper } from "components/PageWrapper"
import { PageTitle, TextWrapper } from "../reusableComponents"
import { UserContext } from "context/UserContext.js"
import { FlowContext } from "context/FlowContext.js"

const ScreeningScreen = ({ data }) => {
  const { goToScreen } = useContext(FlowContext)
  const { storeWorriedAbout } = useContext(UserContext)
  return (
    <PageWrapper>
      <IntroGrid>
        <TextWrapper>
          <PageTitle>{data.heading}</PageTitle>
          <TextWrapper dangerouslySetInnerHTML={{ __html: data.textLeft }} />
        </TextWrapper>
        <ButtonsBlock>
          <TextWrapper dangerouslySetInnerHTML={{ __html: data.textRight }} />

          <ButtonsWrapper var={data.var}>
            <IntroButton
              label={data.button1.label}
              onClick={() => {
                goToScreen(data.button1.screenId)
              }}
            />
            <IntroButton
              label={data.button2.label}
              onClick={() => {
                goToScreen(data.button2.screenId)
              }}
            />
          </ButtonsWrapper>
        </ButtonsBlock>
      </IntroGrid>
    </PageWrapper>
  )
}

export default ScreeningScreen
