import React from "react"
import { Container, TextSection, Title, CardsSection } from "./style"
import { PageWrapper } from "../PageWrapper"
import { Cards } from "../Cards"

const CompositeScaleCardsScreen = ({ data, id }) => {
  return (
    <PageWrapper>
      <Container>
        <TextSection>
          <Title>{data.heading}</Title>
          <TextSection dangerouslySetInnerHTML={{ __html: data.textLeft }} />
        </TextSection>
        <CardsSection>
          <TextSection dangerouslySetInnerHTML={{ __html: data.textRight }} />

          <Cards
            cards={data.cardsData}
            numberOfCardsToShow={4}
            compositeScale
            numbered
            id={id}
          />
        </CardsSection>
      </Container>
    </PageWrapper>
  )
}

export default CompositeScaleCardsScreen
