import React, { useEffect, useState } from "react"
import {
  BarContainer,
  ValueIndicator,
  BarLabelContainer,
  BarLabel,
  ColoredBarLabel,
} from "./style"
import getColor from "helpers/getColor"

const color1 = "007eae"
const color2 = "de0067"

const Bar = ({ label, value }) => {
  const [percentage, setPercentage] = useState()

  const barColor = getColor(color1, color2, value / 100)
  console.log(barColor)
  useEffect(() => {
    setTimeout(() => {
      setPercentage(value)
    }, 400)
  }, [])

  return (
    <BarContainer>
      <ValueIndicator
        style={{ width: `${percentage}%`, backgroundColor: `#${barColor}` }}
      >
        <BarLabelContainer style={{ overflow: "hidden" }}>
          <BarLabel>{label}</BarLabel>
        </BarLabelContainer>
      </ValueIndicator>
      <ColoredBarLabel style={{ color: barColor !== 'NaNNaNNaN' ? `#${barColor}` : "#007EAE" }}>
        {label}
      </ColoredBarLabel>
    </BarContainer>
  )
}

export default Bar
