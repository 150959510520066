import React from "react"
import { CheckboxContainer, Input, Label, Checkmark } from "./style"

const Checkbox = ({ name, value, type, onChange, className, label }) => {
  return (
    <>
      <CheckboxContainer className={className}>
        <Label htmlFor={value}>{label || value}</Label>
        <Input
          id={value}
          name={name}
          value={value}
          type={type}
          onChange={onChange}
        />
        <Checkmark className="checkmark" />
      </CheckboxContainer>
    </>
  )
}

export default Checkbox

Checkbox.defaultProps = {
  type: "checkbox",
}
