import React, { useContext } from "react"
import {
  VideoContainer,
  ImageContainer,
  InfoContainer,
  PlayButton,
} from "./style"
import { Icon } from "utils/getIcon"
import { PopUpContext } from "context/PopUpContext"

const VideoTeaser = ({ small, url, title, img }) => {
  const { togglePopUp } = useContext(PopUpContext)

  return (
    <VideoContainer small={small}>
      <PlayButton onClick={() => togglePopUp(true, "video", url)} />
      <ImageContainer small={small}>
        <Icon name="play" />
        {img && <img src={img} alt="" />}
      </ImageContainer>
      <InfoContainer small={small}>
        <span>Watch: </span>
        <p>{title}</p>
      </InfoContainer>
    </VideoContainer>
  )
}

export default VideoTeaser
