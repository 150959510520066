import React from "react"
import { ProgressDotsContainer, Dot } from "./style"

const ProgressDots = ({ currentPosition, items, className }) => {
  return (
    <ProgressDotsContainer className={className}>
      {items.map((item, index) => {
        return (
          <Dot
            key={index}
            done={currentPosition > index}
            current={currentPosition === index}
          />
        )
      })}
    </ProgressDotsContainer>
  )
}

export default ProgressDots
