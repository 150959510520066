import React, { useContext } from "react"
import {
  IntroGrid,
  ButtonsBlock,
  IntroButton,
} from "components/IntroScreen/style"
import { PageWrapper } from "components/PageWrapper"
import { PageTitle, TextWrapper } from "../reusableComponents"
import { UserContext } from "context/UserContext.js"

const WorriedAboutScreen = () => {
  const { storeWorriedAbout, user } = useContext(UserContext)
  return (
    <PageWrapper>
      <IntroGrid>
        <TextWrapper>
          <PageTitle>
            {user.branch === "victim"
              ? `Something that happened to you`
              : user.branch === "perpetrator"
              ? "Something you did to someone else"
              : "A friend"}
          </PageTitle>
        </TextWrapper>
        <ButtonsBlock>
          <TextWrapper>
            <p className="margin">
              {user.branch === "victim"
                ? `When you think about what happened to you, what’s the main thing
              you’re worried about?`
                : user.branch === "perpetrator"
                ? `When you think about what you did, what’s the main thing
                you’re worried about?`
                : `When you think about what happened to your friend, what’s the main thing
              you’re worried about?`}
            </p>
          </TextWrapper>
          <IntroButton
            label="A relationship (past or current)"
            onClick={() => {
              storeWorriedAbout("relationship")
            }}
          />
          <IntroButton
            label="A sexual experience (not in a relationship)"
            onClick={() => {
              storeWorriedAbout("sexual-experience")
            }}
          />
        </ButtonsBlock>
      </IntroGrid>
    </PageWrapper>
  )
}

export default WorriedAboutScreen
