import styled from "styled-components"
import { device } from "utils/breakpoints.js"
import { Button } from "components/Button"
import { Checkbox } from "../Checkbox"
import { FeelingInput } from "components/PopUp/style"
import { Card } from "components/Cards/Card"
import { RatherNotAnswerBtn } from "../Cards/style"
export const SkipButton = styled(Button)`
  text-decoration: underline;
  font-weight: 500 !important;
  width: auto;
  padding: 0;
  font-size: 18px;
  height: 18px;
  /* transform: translateY(10px); */
  margin-left: 5px;
  @media ${device.desktop} {
    font-size: 24px;
    margin-left: 12px;
  }

  > div {
    margin-right: 10px;

    @media ${device.desktop} {
      margin-right: 20px;
    }
  }
`
export const ExtendedCheckbox = styled(Checkbox)`
  list-style-type: none;
  color: #9cacae;
  text-align: left;
  padding-left: 45px;
  padding-bottom: 10px;
  @media ${device.desktop} {
    padding-bottom: 15px;
    padding-top: 17px;
  }
  a {
    color: #9cacae;
  }
  span.checkmark {
    margin-left: 0;
    margin-top: 19px;

    @media ${device.desktop} {
      margin-top: 17px;
    }
  }

  label {
    padding-left: 0;

    @media ${device.desktop} {
      padding-top: 0;
    }
  }
`

export const SectionContainer = styled.div`
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  max-width: 785px;
  margin: 0 auto;
  padding: 25px 22px;
  background: #ffffff;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  margin-bottom: 17px;
  padding-bottom: 34px;
  @media ${device.desktop} {
    padding: 40px;
    margin-bottom: 40px;
    padding-bottom: 75px;
  }

  h3 {
    font-size: 22px;
    color: #007eae;
    margin-bottom: 15px;
    @media ${device.desktop} {
      font-size: 28px;
    }
  }

  > p {
    /* font-size: 16px; */
    color: #4a5a80;
    padding-bottom: 23px;
    margin-bottom: 10px;
    position: relative;

    @media ${device.desktop} {
      font-size: 20px;
      padding-bottom: 30px;
      margin-bottom: 14px;
    }
    :after {
      content: "";
      position: absolute;
      width: 140%;
      left: 50%;
      transform: translate3d(-50%, 0, 0);
      bottom: 0;
      height: 1px;
      background: #eaeaea;
    }
  }
`

export const DescribeFeelingsInput = styled(FeelingInput)`
  resize: none;
  height: 120px;
  width: calc(100% - 88px);
  align-self: center;
  min-height: auto;
`

export const ExtendedSlider = styled(Card)`
  position: relative;
  width: 100%;
  left: auto;
  transform: none !important;
  box-shadow: none;
  background: none;
  padding: 20px 0 10px 0;
  opacity: 1 !important;

  @media ${device.desktop} {
    padding: 20px 0;
  }
  h4 {
    text-align: left;
    font-size: 16px;
    @media ${device.desktop} {
      font-size: 20px;
    }
  }

  > * {
    display: block !important;
  }

  .buttons-container,
  button.skip {
    display: none !important;
  }

  span {
    :before {
      z-index: 1;
    }
  }
  :after {
    content: "";
    position: absolute;
    width: 140%;
    left: 50%;
    transform: translate3d(-50%, 0, 0);
    bottom: 0;
    height: 1px;
    background: #eaeaea;
  }

  :last-of-type {
    :after {
      display: none;
    }
  }
`

export const ButtonsContainer = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  margin: 0 auto;
  max-width: 785px;
  @media ${device.desktop} {
    grid-gap: 24px;
  }

  a,
  button {
    width: 100% !important;
  }
`

export const LargeRatherNotSay = styled(RatherNotAnswerBtn)`
  height: 60px;
  width: 100%;
  padding: 0 14px;
  font-size: 12px;
  margin: 0;
  font-family: "Azo Sans", "Arial", "sans-serif";
  @media ${device.desktop} {
    width: 100%;
    height: 100px;
    font-size: 16px;
  }
`

export const Submit = styled(LargeRatherNotSay)`
  background: #5825e4;
  font-size: 16px;
  font-family: "Adelle", "Times New Roman", "serif";

  @media ${device.desktop} {
    font-size: 24px;
  }

  :hover {
    background: #4310d1;
  }
`
