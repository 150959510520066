import styled from "styled-components"
import { device } from "utils/breakpoints.js"

import { StandardGrid } from "components/reusableComponents"

export const ContentGrid = styled(StandardGrid)`
  @media ${device.large} {
    grid-gap: 60px;
  }
`
export const ContentBlock = styled.div`
  background: #ffffff;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  font-size: 16px;
  color: #4a5a80;
  padding: 30px 20px;
  align-self: flex-start;
  width:100%;
  max-width: 540px;
  justify-self: center;

  span {
    font-weight: bold;
  }

  @media ${device.tablet} {
    padding: 30px;
    padding-bottom: 45px;
  }
  @media ${device.desktop} {
    font-size: 20px;
    padding: 40px 35px;
    padding-bottom: 60px;
  }

  p {
    margin-bottom: 20px;

    a {
      color: #007eae;
      display: inline-block;
      position: relative;
      cursor: pointer;

      ::after {
        content: "";
        position: absolute;
        left: 0;
        bottom: 3px;
        display: inline-block;
        height: 1px;
        width: 100%;
        background: #007eae;
      }
    }
    .noUnderline {
      ::after {
        height: 0px !important;
      }
    }
  }

  ul {
    margin-left: 24px;
    margin-bottom: 20px;
  }

  li {
    list-style-type: none;
    position: relative;
    margin-bottom: 20px;

    ::before {
      content: "";
      height: 8px;
      width: 8px;
      background: #aaa1d5;
      display: block;
      border-radius: 50%;
      position: absolute;
      top: 6px;
      left: -22px;

      @media ${device.large} {
        top: 8px;
      }
    }
  }

  *:last-child {
    margin-bottom: 0;
  }
`
