import styled from "styled-components"
import { device } from "utils/breakpoints.js"
import { Button } from "components/Button"
import { StandardGrid } from "components/reusableComponents"

export const IntroGrid = styled(StandardGrid)`
  /* @media ${device.tablet} {
    grid-template-columns: 1fr 1fr;
  } */
  .intro {
    opacity:0;

  }
`

export const ButtonsBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  pointer-events: all;
  > div {
    /* max-width: 560px; */
  }
`
export const ButtonsWrapper = styled.div`
  display: grid;
  grid-template-columns: ${props => (props.var ? "1fr 1fr" : "1fr")};
  grid-gap: ${props => (props.var ? "20px" : null)};
  width: 100%;
`
export const IntroButton = styled(Button)`
  font-size: 16px;
  color: white;
  background: #3b10b1;
  border-radius: 5px;
  font-weight: 500;
  height: 60px;
  width: 100%;
  margin-bottom: 20px;
  max-width: 560px;
  @media ${device.desktop} {
    margin-bottom: 30px;
    font-size: 24px;
    height: 100px;
  }


  :hover {
    background: #2e0b8e;
  }

  :nth-of-type(2) {
    background: #5825E4;

    :hover {
      background: #4916D3;
    }
  }
  
  }
  :last-of-type {
    margin: 0;
    background: #007EAE;

    :hover {
      background: #01739F;
    }
  }
 
`
