import React from "react"
import { Container, TextSection, Title, CardsSection } from "./style"
import { PageWrapper } from "../PageWrapper"
import { Cards } from "../Cards"

const cardsData = [
  {
    primaryText:
      "How healthy do you think your relationship is with your partner or ex-partner?",
    options: [
      "Very unhealthy or abusive",
      "Somewhat unhealthy",
      "Healthy Relationship",
    ],
  },
  {
    primaryText:
      "How healthy do you think your relationship is with your partner or ex-partner?",
    options: [
      "Very unhealthy or abusive",
      "Somewhat unhealthy",
      "Healthy Relationship",
    ],
  },
  {
    primaryText:
      "How healthy do you think your relationship is with your partner or ex-partner?",
    options: [
      "Very unhealthy or abusive",
      "Somewhat unhealthy",
      "Healthy Relationship",
    ],
  },
]

const NumberScaleCardsScreen = ({ data, id }) => {
  return (
    <PageWrapper>
      <Container>
        <TextSection>
          <Title>{data.heading}</Title>
          <TextSection dangerouslySetInnerHTML={{ __html: data.textLeft }} />
        </TextSection>
        <CardsSection>
          <TextSection dangerouslySetInnerHTML={{ __html: data.textRight }} />
          <Cards
            cards={cardsData}
            numberOfCardsToShow={4}
            numberedScale
            id={id}
          />
        </CardsSection>
      </Container>
    </PageWrapper>
  )
}

export default NumberScaleCardsScreen
