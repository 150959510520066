import React, { useContext, useState, useEffect } from "react"
import { ContentGrid, FeedbackBlock, InfoText } from "./style"
import { PageWrapper } from "components/PageWrapper"
import { PageTitle, TextWrapper } from "../reusableComponents"
import { BarGraph } from "../BarGraph"
import { UserContext } from "context/UserContext.js"
import arrSum from "helpers/arrSum"
const FeedbackGraphicScreen = ({ data }) => {
  const { user } = useContext(UserContext)
  const [hasResult, setHasResult] = useState(false)

  const getResults = id => {
    const storedScreen = user.responses.find(response => response.id === id)
    //extract number responses
    const filteredScores = storedScreen.results.filter(item => {
      return parseInt(item) == item
    })
    return filteredScores
  }

  const getAverage = filteredScores => {
    const resultsAverage = arrSum(filteredScores) / filteredScores.length
    return isNaN(resultsAverage) ? 100 : resultsAverage
  }

  let summaryText = `It looks like the way you communicate with your partner in situations of conflict is similar to how you communicate at work and with friends. It's a good idea to reflect every so often on how you're communicating with your partner, especially when things are challenging.`

  const workResults = getResults("2.2.2.7-workSliders")
  console.log("workResults:", workResults)
  const workAverage = getAverage(workResults)
  const partnerResults = getResults("2.2.2.7-partnerSliders")
  console.log("partnerResults:", partnerResults)
  const partnerAverage = getAverage(partnerResults)
  const friendResults = getResults("2.2.2.7-friendSliders")
  console.log("friendResults:", friendResults)
  const friendAverage = getAverage(friendResults)
  const aResults = [workAverage, partnerAverage, friendAverage].sort(function(
    a,
    b
  ) {
    return a - b
  })

  if (aResults[2] > aResults[0]) {
    summaryText = `You might want to reflect on why you are using different ways of communicating during challenging times, and whether you want to change this.`
  }

  if (!hasResult) {
    summaryText = `Unfortunately we can't provide you with feedback on this section
    since you didn't answer any of the questions`
  }

  useEffect(() => {
    if (workResults.length || partnerResults.length || friendResults.length) {
      setHasResult(true)
    }
  }, [])

  useEffect(() => {
    console.log("hasResult:", hasResult)
  }, [hasResult])
  return (
    <PageWrapper>
      <ContentGrid>
        <TextWrapper>
          <PageTitle>{data.heading}</PageTitle>
          <TextWrapper>
            <p>{summaryText}</p>
          </TextWrapper>
        </TextWrapper>

        <FeedbackBlock>
          <BarGraph
            workAverage={workAverage}
            partnerAverage={partnerAverage}
            friendAverage={friendAverage}
            hasResult={hasResult}
          />
      
        </FeedbackBlock>
      </ContentGrid>
    </PageWrapper>
  )
}

export default FeedbackGraphicScreen
