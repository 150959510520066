import React, { useEffect, useContext } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

import { PopUp } from "../components/PopUp"
import { Pagination } from "../components/Pagination"
import { PopUpContext } from "context/PopUpContext"
import { FlowContext } from "context/FlowContext.js"
import { SelectedScreen } from "../components/SelectedScreen"
//allScreenTypes
import { IntroScreen } from "../components/IntroScreen"
import { WorriedAboutScreen } from "../components/WorriedAboutScreen"
import { ContentScreen } from "../components/ContentScreen"
import { CenteredContentScreen } from "../components/CenteredContentScreen"
import { YesNoCardsScreen } from "../components/YesNoCardsScreen"
import { BigYesNoCardsScreen } from "../components/BigYesNoCardsScreen"
import { SliderCardsScreen } from "../components/SliderCardsScreen"
import { NumberScaleCardsScreen } from "../components/NumberScaleCardsScreen"
import { CompositeScaleCardsScreen } from "../components/CompositeScaleCardsScreen"
import { FeedbackGraphicScreen } from "../components/FeedbackGraphicScreen"
import { TalkingFeedbackScreen } from "../components/TalkingFeedbackScreen"
import { VideoScreen } from "../components/VideoScreen"
import { CheckboxScreen } from "../components/CheckboxScreen"
import { UnansweredQuestionsScreen } from "../components/UnansweredQuestionsScreen"
import { ActionPlanScreen } from "../components/ActionPlanScreen"
import { ScreeningScreen } from "../components/ScreeningScreen"

const IndexPage = () => {
  const {
    currentScreen,
    resetFlow,
    flowDisabled,
    animationDuration,
    currentScreenIndex,
    screensLength,
    trackEvent
  } = useContext(FlowContext)
  const screenTemplateName = currentScreen.template
  const screenData = currentScreen.data

  const { togglePopUp } = useContext(PopUpContext)

  useEffect(() => {

    trackEvent("start", "start");
    if (localStorage.getItem("firstRun")) {
      return function cleanup() {
        resetFlow()
      }
    } else {
      togglePopUp(true, "questionnaire")
      localStorage.setItem("firstRun", false)
      return function cleanup() {
        resetFlow()
      }
    }
  }, [])

  function renderTemplate(props) {
    switch (screenTemplateName) {
      case "IntroScreen":
        return <IntroScreen {...props} />
      case "WorriedAboutScreen":
        return <WorriedAboutScreen {...props} />
      case "ContentScreen":
        return <ContentScreen {...props} />
      case "YesNoCardsScreen":
        return <YesNoCardsScreen {...props} />
      case "BigYesNoCardsScreen":
        return <BigYesNoCardsScreen {...props} />
      case "SliderCardsScreen":
        return <SliderCardsScreen {...props} />
      case "NumberScaleCardsScreen":
        return <NumberScaleCardsScreen {...props} />
      case "CompositeScaleCardsScreen":
        return <CompositeScaleCardsScreen {...props} />
      case "FeedbackGraphicScreen":
        return <FeedbackGraphicScreen {...props} />
      case "VideoScreen":
        return <VideoScreen {...props} />
      case "CheckboxScreen":
        return <CheckboxScreen {...props} />
      case "UnansweredQuestionsScreen":
        return <UnansweredQuestionsScreen {...props} />
      case "ActionPlanScreen":
        return <ActionPlanScreen {...props} />
      case "ScreeningScreen":
        return <ScreeningScreen {...props} />
      case "CenteredContentScreen":
        return <CenteredContentScreen {...props} />
      case "TalkingFeedbackScreen":
        return <TalkingFeedbackScreen {...props} />
      default:
        return null
    }
  }

  return (
    <Layout>
      <SEO title="Questionnaire" />
      <PopUp />
      <SelectedScreen
        disabled={flowDisabled}
        animationDuration={animationDuration}
        currentScreenIndex={currentScreenIndex}
        currentScreen={currentScreen}
        screensLength={screensLength}
      >
        {renderTemplate({ data: screenData, id: currentScreen.id })}
      </SelectedScreen>
      <Pagination
        currentScreenIndex={currentScreenIndex}
        screensLength={screensLength}
        disabled={flowDisabled}
        hide={screenData.noPagination}
        data={screenData}
      />
    </Layout>
  )
}

export default IndexPage
