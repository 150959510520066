import React from "react"
import { ContentGrid, ContentBlock } from "./style"
import { PageWrapper } from "components/PageWrapper"
import { TextWrapper } from "../reusableComponents"

const ContentScreen = ({ data }) => {
  return (
    <PageWrapper>
      <ContentGrid>
        <TextWrapper dangerouslySetInnerHTML={{ __html: data.textLeft }} />
        <ContentBlock dangerouslySetInnerHTML={{ __html: data.textRight }} />
      </ContentGrid>
    </PageWrapper>
  )
}

export default ContentScreen
