import React, { useState, useEffect } from "react"
import {
  ExtendedCheckbox,
  SectionContainer,
  DescribeFeelingsInput,
} from "./style"

const CheckboxList = ({
  title,
  prompt,
  questions,
  id,
  checked,
  setChecked,
}) => {
  const toggleChecked = event => {
    const target = event.target
    const value = target.value
    const itemExists = checked.includes(value)

    if (itemExists) {
      const updatedCheckedItems = checked.filter(item => item !== value)
      setChecked([...updatedCheckedItems])
    } else {
      setChecked([...checked, value])
    }
  }

  return (
    <SectionContainer>
      <h3>{title}</h3>
      <p>{prompt}</p>

      {questions.map((question, index) => {
        return (
          <ExtendedCheckbox
            key={index}
            onChange={toggleChecked}
            name={id}
            value={question.primaryText}
          />
        )
      })}

      {/* <DescribeFeelingsInput
        placeholder="Please type here to explain how you feel. If you don’t feel comfortable sharing, feel free to skip this question."
        value={feelingsResponse}
        onChange={e => setFeelingsResponse(e.target.value)}
      /> */}
    </SectionContainer>
  )
}

export default CheckboxList
